var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4 white rounded-lg elevation-0"
  }, [_c('v-row', {
    staticClass: "px-4 pt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).items.value,
      "item-text": "id",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ProfileIds).value"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "full-width": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Name).value"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentNumber).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "full-width": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentNumber).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentNumber), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.AssignmentNumber).value"
    }
  }), _c('div', {
    staticClass: "mt-n1"
  }, [_c('v-chip-group', {
    attrs: {
      "active-class": "primary"
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Type).value"
    }
  }, _vm._l(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type).items.value, function (item) {
    return _c('v-chip', {
      key: item,
      staticClass: "mx-1",
      attrs: {
        "value": item
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("baseData.profiles.filters.type.".concat(item.toLowerCase()))) + " ")]);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }